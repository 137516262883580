<template>
  <div style="display: flex;flex-wrap: wrap;">
    <div v-for="item in tabBarList" style="margin-right: 20px;margin-bottom: 20px;" :key="item.text">
      <el-card :body-style="{ padding: '0px',width: '200px',height: '450px' }">
        <img :src="item.imgPath" class="image">
        <div style="width: 200px;display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 12px 0">
          <div style="width: 33%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 12px">{{ item.text }}</div>
          <div style="width: 67%">
            <el-button type="primary" class="button1" @click="handleEdit(item)">编辑</el-button>
<!--            <el-button type="danger" class="button1">删除</el-button>-->
            <el-popconfirm
                class="ml-5"
                confirm-button-text='确定'
                cancel-button-text='我再想想'
                icon="el-icon-info"
                icon-color="red"
                title="您确定删除吗？"
                @confirm="del(item.id)"
            >
              <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
            </el-popconfirm>
          </div>
        </div>
      </el-card>


    </div>
    <div style="margin-right: 20px;margin-bottom: 20px;">
      <el-card :body-style="{ padding: '0px',width: '200px',height: '450px' }">
        <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;" @click="handleAdd(null)">
          <i class="el-icon-plus" style="font-size: 68px;"></i>
        </div>
      </el-card>
    </div>

    <el-dialog title="toBar信息" :visible.sync="dialogFormVisible" width="30%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="名称">
          <el-select clearable v-model="form.text" placeholder="请选择名称" style="width: 100%" @change="selectChange">
            <el-option v-for="item in tabBarTemp" :key="item.text" :label="item.text" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页面路径">
          <el-input v-model="form.pagePath" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.description" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入tabBar页面描述" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="样式图">
          <div style="display: flex;flex-direction: row;justify-content: space-around;">
            <div style="width: 30%;">
              <el-image v-if="form.imgPath" :src="form.imgPath" style="transform: rotate(0deg);height: 100px;width: 60px;">
                <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
              </el-image>
            </div>
            <div style="width: 70%;">
              <el-upload
                  class="upload-demo" :headers="{ token: user.token }"
                  :action="$baseUrl + '/tab-bar/upload'" :show-file-list="false"
                  :on-success="handleUploadSuccess" :data="{text: form.text}"
              >
                <el-button size="small" type="primary">上传截图</el-button>
                <div slot="tip" class="el-upload__tip">请选择一张tabBar页面截图上传，以便识别页面</div>
              </el-upload>
            </div>
          </div>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {serverIp} from "../../../../public/config";
import axios from "axios";

export default {
  name: "Tobar",
  data() {
    return {
      tabBarList: [],  // tabBar列表
      dialogFormVisible: false,
      form: {},
      tabBarJson: [],   // 备选tabBar, 从tabBar.json文件读取
      tabBarTemp: [],   // list选择，已经先过略
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
created() {
  this.load()
},
methods: {
  selectChange(item){
    this.form.pagePath = item.pagePath
    this.form.iconPath = item.iconPath
    this.form.selectedIconPath = item.selectedIconPath
    this.form.text = item.text
  },
  load() {
    let that = this
    that.request.get("/tab-bar", {
      params: {
        name: that.name,
      }
    }).then(res => {
      console.log(res.data)
      that.tabBarList = res.data
      axios.get('/app.json')
          .then(res => {
            //console.log("tabBar.json==>", res.data.tabBar.list)
            that.tabBarJson = res.data.tabBar.list
            that.tabBarTemp = res.data.tabBar.list
            if(res.data.tabBar.list){
              that.tabBarList.forEach((it)=>{
                // console.log("it.text==>", it.text)
                that.tabBarTemp = that.tabBarTemp.filter(item => item.text !== it.text);
              })
            }
            // console.log("tabBar.json==>", this.tabBarTemp)
          })
          .catch(error => {
            console.log(error)
          })
    })

    // 请求图标的数据
    // this.request.get("/menu/icons").then(res => {
    //   this.options = res.data
    // })
  },
  save() {console.log( this.form)
    this.request.post("/tab-bar", this.form).then(res => {
      if (res.code === '200') {
        this.$message.success("保存成功")
        this.dialogFormVisible = false
        this.load()
      } else {
        this.$message.error("保存失败")
      }
    })
  },
  handleUploadSuccess(res) {
    console.log(res)
    this.form.imgPath = res.data
  },
  handleAdd(pid) {
    this.dialogFormVisible = true
    this.form = {}
    if (pid) {
      this.form.pid = pid
    }
  },
  handleEdit(row) {
    this.form = JSON.parse(JSON.stringify(row))
    this.dialogFormVisible = true
  },
  del(id) {
    this.request.delete("/tab-bar/" + id).then(res => {
      if (res.code === '200') {
        this.$message.success("删除成功")
        this.load()
      } else {
        this.$message.error("删除失败")
      }
    })
  },
  handleSelectionChange(val) {
    console.log(val)
    this.multipleSelection = val
  },
  delBatch() {
    let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
    this.request.post("/tab-bar/del/batch", ids).then(res => {
      if (res.code === '200') {
        this.$message.success("批量删除成功")
        this.load()
      } else {
        this.$message.error("批量删除失败")
      }
    })
  },
  reset() {
    this.name = ""
    this.load()
  },
  handleSizeChange(pageSize) {
    console.log(pageSize)
    this.pageSize = pageSize
    this.load()
  },
  handleCurrentChange(pageNum) {
    console.log(pageNum)
    this.pageNum = pageNum
    this.load()
  },
  exp() {
    window.open(`http://${serverIp}:9090/role/export`)
  },
  handleExcelImportSuccess() {
    this.$message.success("导入成功")
    this.load()
  }
  }
}

</script>

<style scoped>

.image {
  width: 200px;
  height: 400px;
  display: block;
}

</style>